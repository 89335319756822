import Decimal from '@st/decimal'
import { useTariffsStore } from '../../../stores/useTariffsStore'

export interface UseWithdrawalFeeParams {
  amount: Ref<string>
  rolledWithdrawalAmount: Ref<string>
  currencyId: Ref<number | undefined>
  networkId: Ref<number | undefined>
  isEnabledRolling: Ref<boolean>
}

export interface UseWithdrawalFeeReturn {
  feeType: Ref<'withdrawal' | 'withdrawalUnrolled'>
  feeAmount: Ref<string>
}

export function useWithdrawalFee({
  amount,
  currencyId,
  networkId,
  rolledWithdrawalAmount,
  isEnabledRolling,
}: UseWithdrawalFeeParams): UseWithdrawalFeeReturn {
  const { fees } = storeToRefs(useTariffsStore())

  const filteredFees = computed(() => {
    if (!fees.value) return []

    const allowedTypes = new Set(['withdrawal', 'withdrawalUnrolled'])
    return fees.value.filter(
      (fee) =>
        fee.currencyId === currencyId.value &&
        fee.networkId === networkId.value &&
        allowedTypes.has(fee.operationType),
    )
  })

  const normalFee = computed(() =>
    filteredFees.value.find((fee) => fee.operationType === 'withdrawal'),
  )

  const unrolledFee = computed(() =>
    filteredFees.value.find(
      (fee) => fee.operationType === 'withdrawalUnrolled',
    ),
  )

  const isUnrolled = computed(() =>
    new Decimal(amount.value).greaterThan(rolledWithdrawalAmount.value),
  )

  const finalFee = computed(() => {
    if (isEnabledRolling.value && isUnrolled.value && unrolledFee.value) {
      return unrolledFee.value
    }
    return normalFee.value
  })

  const feeAmount = computed(() => {
    const fee = finalFee.value

    if (!fee) return '0'

    if (
      fee.zeroFeeFromAmount &&
      new Decimal(amount.value).greaterThanOrEqualTo(fee.zeroFeeFromAmount)
    ) {
      return '0'
    }

    return new Decimal(amount.value)
      .mul(fee.percent)
      .div(100)
      .plus(fee.fix)
      .clampedTo(fee.minAmount, fee.maxAmount)
      .toString()
  })

  const feeType = computed(
    () =>
      (finalFee.value?.operationType ?? 'withdrawal') as
        | 'withdrawal'
        | 'withdrawalUnrolled',
  )

  return {
    feeAmount,
    feeType,
  }
}

<template>
  <div class="deposit-address" :class="platform" data-t="deposit-address">
    <div class="address-wrapper">
      <div class="title">{{ t('payments.deposit.depositAddress') }}</div>
      <div class="content-wrapper">
        <div class="content">
          <StSkeletonLoader
            v-if="isLoading"
            type="rectangle"
            :width="platform === 'desktop' ? '108px' : '68px'"
            :height="platform === 'desktop' ? '108px' : '68px'"
            :shimmer-opacity="0.32"
          />
          <div v-else class="qr-code">
            <canvas ref="canvas" class="canvas"></canvas>
          </div>
          <div class="address">
            <StSkeletonLoader
              v-if="isLoading"
              type="rectangle"
              :width="platform === 'desktop' ? '390px' : '196px'"
              height="50px"
              :shimmer-opacity="0.32"
            />
            <template v-else>
              <div class="description">
                {{ t('payments.deposit.scanCode') }}
              </div>
              <div class="wallet-address" data-t="wallet-address">
                {{ address }}
              </div>
            </template>
            <div v-if="platform === 'desktop'" class="copy-block">
              <div class="copy">
                <StButton
                  v-if="address"
                  :label="
                    isCopied
                      ? t('payments.deposit.copied')
                      : t('payments.deposit.copy')
                  "
                  size="s"
                  type="gray"
                  :icon="isCopied ? 'check' : 'copy-solid'"
                  is-left-icon
                  class="copy-button"
                  @click="handleCopy(address)"
                >
                </StButton>
              </div>
            </div>
          </div>
        </div>
        <StButton
          v-if="platform === 'mobile' && address"
          size="m"
          type="gray"
          is-left-icon
          :icon="isCopied ? 'check' : 'copy-solid'"
          :label="
            isCopied ? t('payments.deposit.copied') : t('payments.deposit.copy')
          "
          @click="handleCopy(address)"
        />
      </div>
      <div class="hints">
        <div class="hint">
          <div class="icon">
            <StIcon
              name="circle-info-solid"
              :size="platform === 'desktop' ? '20' : '16'"
            />
          </div>
          <div class="text">
            <i18n-t keypath="payments.deposit.hintAddress">
              <template #currency>
                <span data-t="selected-currency">
                  {{ selectedCurrency.code }}
                </span>
              </template>
              <template #network>
                <span data-t="selected-network">
                  {{ selectedNetwork?.description }}
                </span>
              </template>
            </i18n-t>
          </div>
        </div>
        <div v-if="minimalDeposit" class="hint">
          <div class="icon">
            <StIcon
              :name="selectedCurrency.icon"
              :size="platform === 'desktop' ? '17.5' : '13.5'"
            />
          </div>
          <div class="text" data-t="minimal-deposit">
            <i18n-t keypath="payments.deposit.minimalDeposit">
              <template #currency>
                <span>
                  {{ selectedCurrency.code }}
                </span>
              </template>
              <template #amount>
                <span>
                  {{ minimalDeposit }}
                </span>
              </template>
            </i18n-t>
          </div>
        </div>
      </div>
    </div>
    <template v-if="!selectedNetwork">
      <div class="overlay-text" :class="platform">
        <div class="icon">
          <StIcon name="arrow-right" size="48" />
        </div>
        <div class="text">{{ t('payments.deposit.overlayText') }}</div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import QRCode from 'qrcode'
import type { Currency, Network } from '../../interfaces'
import { useTariffsStore } from '../../stores/useTariffsStore'

interface Props {
  loading?: boolean
  selectedCurrency: Currency
  selectedNetwork?: Network
  address?: string
  platform: 'desktop' | 'mobile'
}

const props = withDefaults(defineProps<Props>(), { platform: 'desktop' })
const { t } = useI18n()
const canvas = ref<HTMLCanvasElement | null>(null)

const canvasCreated = ref(false)

watchEffect(() => {
  if (!props.address) {
    return
  }

  QRCode.toCanvas(canvas.value, props.address, {
    margin: 3,
    width: props.platform === 'desktop' ? 108 : 68,
  }).then(() => {
    canvasCreated.value = true
  })
})

const { copy } = useClipboard()

const isCopied = ref(false)
function handleCopy(value: string) {
  copy(value)
  isCopied.value = true

  setTimeout(() => {
    isCopied.value = false
  }, 3000)
}

const { findLimit } = useTariffsStore()
const { format } = useCryptoFormatter()

const minimalDeposit = computed(() => {
  if (!props.selectedNetwork?.id) return ''

  const foundLimit = findLimit({
    currencyId: props.selectedCurrency.id,
    networkId: props.selectedNetwork?.id,
    operationType: 'deposit',
  })

  if (!foundLimit || !Number(foundLimit.minAmount)) return ''

  return format(foundLimit.minAmount)
})

const isLoading = computed(
  () => props.loading || (props.address && !canvasCreated.value),
)
</script>

<style scoped>
.deposit-address {
  position: relative;
  max-width: 548px;
}

.address-wrapper {
  display: flex;
  flex-direction: column;
}

.title {
  font: var(--desktop-text-lg-semibold);
}

.content-wrapper {
  display: flex;
  flex-direction: column;

  padding: var(--spacing-200);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-100);
}

.content {
  display: flex;
  gap: var(--spacing-200);
  align-items: center;
}

.qr-code {
  width: 108px;
  height: 108px;
  background-color: var(--palette-light-1000);
  border-radius: var(--border-radius-100);

  .canvas {
    border-radius: var(--border-radius-100);
  }
}

.address {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-075);
  justify-content: space-between;

  min-height: 108px;

  .description {
    font: var(--desktop-text-xs-medium);
    color: var(--palette-light-600);
  }

  .wallet-address {
    max-width: 392px;
    font: var(--desktop-text-md-semibold);
    word-wrap: break-word;
  }

  .copy-block {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.hint {
  display: flex;
  gap: var(--spacing-100);
  align-items: flex-start;

  margin-top: var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);

  .text {
    span {
      color: var(--palette-light-1000);
    }
  }

  .icon {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    line-height: 0;
  }
}

.overlay-text {
  pointer-events: none;

  position: absolute;
  top: -16px;
  bottom: -16px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgb(17 17 24 / 80%);
  backdrop-filter: blur(10px);

  .text {
    max-width: 400px;
    font: var(--desktop-text-xl-semibold);
    text-align: center;
  }

  .icon {
    transform: rotate(270deg);
    margin-bottom: var(--spacing-125);
    color: var(--icon-disable);
    animation: down-up 0.75s infinite alternate;
  }

  &.mobile {
    right: -16px;
    left: -16px;
  }

  &.desktop {
    right: -24px;
    left: -24px;
  }
}

.copy-button {
  &:hover {
    :deep(.button-icon) {
      background-color: var(--palette-light-1000) !important;
    }
  }
}

@keyframes down-up {
  0% {
    transform: rotate(270deg) translateX(0);
  }

  100% {
    transform: rotate(270deg) translateX(16px);
  }
}

.deposit-address.mobile {
  .title {
    font: var(--mobile-headline-semibold);
  }

  .content-wrapper {
    gap: var(--spacing-200);
    margin: var(--spacing-100) 0;
  }

  .description {
    font: var(--mobile-caption-1-regular);
  }

  .content {
    gap: var(--spacing-200);
  }

  .address {
    justify-content: center;
    min-height: auto;
  }

  .wallet-address {
    max-width: none;
    font: var(--mobile-text-content-regular);
    overflow-wrap: anywhere;
  }

  .qr-code {
    width: 68px;
    height: 68px;
  }

  .hint {
    margin-top: var(--spacing-100);
    font: var(--mobile-caption-1-regular);
  }

  .overlay-text {
    .text {
      font: var(--mobile-headline-semibold);
    }
  }
}
</style>

<template>
  <div class="deposit" :class="platform" data-t="deposit">
    <NavigationTabs v-if="current === 'methods'" :type="platform" />
    <DepositMethods
      v-if="current === 'methods'"
      :type="platform"
      @handle-currency-selected="handleCurrencySelected"
    />
    <DepositForm
      v-if="current === 'form'"
      :type="platform"
      :selected-currency-id="selectedCurrencyId"
      @back="goToPrevious"
    />
  </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{ platform?: 'desktop' | 'mobile' }>(), {
  platform: 'desktop',
})

const { goTo, current, goToPrevious } = useStepper(['methods', 'form'])

const selectedCurrencyId = ref()

function handleCurrencySelected(id: number) {
  selectedCurrencyId.value = id
  goTo('form')
}
</script>

<style scoped>
.deposit {
  display: flex;
  flex-direction: column;
  min-height: 657px;
  padding-top: var(--spacing-300);
}

.deposit.mobile {
  height: 100%;
  min-height: auto;
  padding-top: 0;
}
</style>

<template>
  <div
    ref="addressSelect"
    class="address-select"
    :class="wrapperClasses"
    data-t="address-select"
  >
    <StBaseSelect
      v-model="baseSelectModelValue"
      :has-drawer="platform === 'mobile'"
      :mobile-title="t('payments.withdrawal.walletAddress')"
    >
      <template #field>
        <AddressField
          :input-value="inputFieldModelValue"
          :size="selectSize"
          :platform="platform"
          :is-disabled-input-field="isDisabledInputField"
          :is-verified-address="isVerifiedAddress"
          :error="!!errorMessage"
          @clear-select-input="clearSelectInput"
          @on-input-value-changed="handleInputValue"
        />
      </template>
      <SelectOptionComponent
        v-for="account in filteredItems"
        :key="account.id"
        :item="account"
        :platform="platform"
        :is-selected="selectedItem?.address === account.address"
        :search-value="inputFieldModelValue"
      />
      <EmptyAddressBook v-if="!filteredItems.length" :type="platform" />
      <div
        class="add-new-wallet"
        :class="platform"
        data-t="actions"
        @click="emit('handleDropdownClick')"
      >
        <StIcon name="plus" />
        {{ t('payments.withdrawal.addNewWalletAddress') }}
      </div>
    </StBaseSelect>
    <StTransitionExpand>
      <div v-if="errorMessage">
        <span v-if="errorMessage" class="error-message" data-t="error">
          {{ errorMessage }}
        </span>
      </div>
    </StTransitionExpand>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@st/use/composables'
import AddressField from './parts/AddressField.vue'
import SelectOptionComponent from './parts/SelectOption.vue'
import EmptyAddressBook from '../EmptyAddressBook/EmptyAddressBook.vue'
import type { SelectOption } from './types'

const emit = defineEmits<{
  (e: 'change', value: number): void
  (e: 'handleDropdownClick'): void
  (e: 'update:modelValue', value: number | string): void
}>()

const props = withDefaults(
  defineProps<{
    modelValue?: number | string
    placeholder?: string
    label?: string
    dataT?: string
    size?: 'm' | 's'
    items: SelectOption[]
    platform: 'desktop' | 'mobile'
    errorMessage?: string
  }>(),
  {
    platform: 'desktop',
  },
)

const { t } = useI18n()

const wrapperClasses = computed(() => [
  `size-${props.size}`,
  `platform-${props.platform}`,
])

const isExpanded = ref(false)

const addressSelect = ref<HTMLDivElement>()
onClickOutside(addressSelect, () => {
  if (props.platform === 'desktop') {
    isExpanded.value = false
  }
})

const filteredItems = ref(props.items)

watch(
  () => props.items,
  () => {
    filteredItems.value = props.items
  },
)

const selectedItem = computed(() =>
  filteredItems.value.find((account) => account.id === props.modelValue),
)

function restoreFilteredItems() {
  filteredItems.value = props.items
}

const baseSelectModelValue = ref('')
const inputFieldModelValue = ref(baseSelectModelValue.value)
const isDisabledInputField = ref(false)
const isVerifiedAddress = ref(false)

watch(baseSelectModelValue, (newValue) => {
  if (!newValue) return

  const clickedItem = props.items.find((item) => item.address === newValue)
  if (!clickedItem) return

  inputFieldModelValue.value = String(clickedItem?.hiddenValue || '')
  isDisabledInputField.value = true

  isVerifiedAddress.value = !clickedItem.twoFactorAuthRequired
  filteredItems.value = props.items

  emit('update:modelValue', newValue)
})

function handleInputValue(value: string) {
  inputFieldModelValue.value = value

  filteredItems.value = props.items.filter((item) =>
    item.address.includes(value),
  )

  emit('update:modelValue', value)
}

function clearSelectInput() {
  inputFieldModelValue.value = ''
  isDisabledInputField.value = false
  baseSelectModelValue.value = ''
  restoreFilteredItems()
  emit('update:modelValue', '')
}

const selectSize = computed(() => {
  if (props.platform === 'mobile') {
    return 'm'
  }

  return props.size
})
</script>

<style scoped>
.address-select {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.add-new-wallet {
  cursor: pointer;

  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100) 0;

  font: var(--desktop-text-sm-semibold);
  color: var(--button-primary-default);
}

.error-message {
  margin-top: var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.add-new-wallet.mobile {
  padding: var(--spacing-300) var(--spacing-200);
}
</style>

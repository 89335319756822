import Decimal from '@st/decimal'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import { useConverterStore } from '../../../stores/useConverterStore'
import { useAccountsStore } from '../../../stores/useAccountsStore'
import { useCurrenciesStore } from '../../../stores/useCurrenciesStore'

interface UseDepositRollingParams {
  currencyId: Ref<number>
}

interface UseDepositRollingReturn {
  /* Столько можно вывести как уже обкатанные деньги */
  rolledWithdrawalAmount: ComputedRef<string>
  /* Столько нужно проставить чтобы обкатать весь баланс */
  rollingAmount: ComputedRef<string>
  isShownRollingInfo: ComputedRef<boolean>
}

export function useDepositRolling({
  currencyId,
}: UseDepositRollingParams): UseDepositRollingReturn {
  const { convert } = useConverterStore()
  const { realAccounts } = storeToRefs(useAccountsStore())
  const { settings } = storeToRefs(useSettingsStore())
  const { currencies, appCurrency } = storeToRefs(useCurrenciesStore())

  const isDepositRollingEnabled = computed(() =>
    Boolean(settings.value?.depositRolling?.enabled),
  )

  const { data: depositRolling } = useStFetch('/user/deposit-rolling-delta', {
    method: 'POST',
  })

  const selectedCurrency = computed(() => currencies.value[currencyId.value])
  const selectedAccount = computed(() =>
    realAccounts.value.find(
      (account) => account.currencyId === currencyId.value,
    ),
  )

  const rollingAmountInCrypto = computed(() =>
    convert(depositRolling.value?.depositRollingDelta ?? 0, {
      from: appCurrency.value.code,
      to: selectedCurrency.value.code,
    }),
  )

  const isShownRollingInfo = computed(() => {
    const isZeroBalance = new Decimal(
      selectedAccount.value?.balance ?? 0,
    ).isZero()

    const isZeroDelta = new Decimal(
      depositRolling.value?.depositRollingDelta ?? 0,
    ).isZero()

    return isDepositRollingEnabled.value && !isZeroDelta && !isZeroBalance
  })

  const withdrawalMaxAmount = computed(() => {
    if (!selectedAccount.value?.balance) return '0'

    const rolledAmount = new Decimal(selectedAccount.value.balance).plus(
      rollingAmountInCrypto.value || 0,
    )

    const result = Decimal.min(rolledAmount, selectedAccount.value.balance)

    return Decimal.max(0, result).toString()
  })

  return {
    rolledWithdrawalAmount: withdrawalMaxAmount,
    rollingAmount: rollingAmountInCrypto,
    isShownRollingInfo,
  }
}

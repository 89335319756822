<template>
  <div :class="platform" class="input-with-select" data-t="input-with-select">
    <h2>
      {{ title }}
      <span> {{ subtitle }} </span>
    </h2>
    <div class="content">
      <div class="input-wrapper">
        <StInput
          v-model="inputModel"
          :placeholder="inputPlaceholder"
          :error-message="error"
          :error="!!error"
          :size="platform === 'desktop' ? 'l' : 'm'"
          inputmode="decimal"
          @focus="emit('handleFocus')"
        >
          <template #postfix>
            <StIcon
              v-if="inputModel"
              class="clear-input"
              name="cross-large"
              size="16"
              @click="clearInput"
            />
          </template>
        </StInput>
      </div>
      <div class="select-wrapper">
        <StBaseSelect
          v-model="selectModel"
          :platform="platform"
          :has-drawer="platform === 'mobile'"
          :is-disabled="isDisabled"
        >
          <template #field="data">
            <PaymentsSelectField
              :first-value="selectedItem?.title"
              :icon-prefix="selectedItem?.icon"
              :icon-postfix="iconPostfix(data)"
              :platform="platform"
            />
          </template>
          <PaymentsSelectOption
            v-for="item in selectItems"
            :key="item.id"
            :item="item"
            :platform="platform"
            without-status-icon
          />
        </StBaseSelect>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IconName } from '@st/ui/components/StIcon/types'
import PaymentsSelectOption from '../PaymentsSelectOption/PaymentsSelectOption.vue'
import PaymentsSelectField from '../PaymentsSelectField/PaymentsSelectField.vue'

const emit = defineEmits<{
  (e: 'update:select', value: number): void
  (e: 'update:input', value: string): void
  (e: 'handleFocus'): void
}>()

const props = withDefaults(
  defineProps<{
    select: number
    input: string
    platform: 'mobile' | 'desktop'
    isDisabled?: boolean
    title?: string
    inputPlaceholder?: string
    error?: string
    subtitle?: string
    selectItems: {
      id: number | string
      title: string
      icon: IconName
    }[]
  }>(),
  {
    platform: 'desktop',
    isDisabled: false,
  },
)

const iconPostfix = computed(() => (data: { isExpanded: boolean }) => {
  if (props.isDisabled) return 'lock-solid'

  return data.isExpanded ? 'chevron-top' : 'chevron-down'
})

const selectModel = computed({
  get() {
    return props.select
  },
  set(value: number) {
    emit('update:select', value)
  },
})

const inputModel = computed({
  get() {
    return props.input
  },
  set(value: string) {
    emit('update:input', value)
  },
})

const selectedItem = computed(() =>
  props.selectItems.find((item) => item.id === selectModel.value),
)

function clearInput() {
  inputModel.value = ''
}
</script>

<style scoped>
.input-with-select {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

h2 {
  width: 100%;
  margin: 0;
  padding: 0;

  font: var(--desktop-text-md-medium);
  text-align: justify;

  span {
    float: right;

    padding-top: var(--spacing-100);

    font: var(--mobile-caption-1-regular);
    color: var(--text-secondary);
    text-align: left;
  }
}

.content {
  display: flex;
  width: 100%;
}

.input-wrapper {
  width: 100%;

  &:deep(.input) {
    border-right: 1px solid var(--border-secondary);
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.clear-input {
  cursor: pointer;
  color: var(--text-tertiary);
}

.select-wrapper {
  min-width: 150px;

  &:deep(.input-wrapper) {
    min-height: 48px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.mobile.input-with-select {
  h2 {
    font: var(--mobile-text-medium);
  }

  .select-wrapper {
    min-width: 120px;
  }

  &:deep(.input-wrapper) {
    min-height: auto;
  }
}
</style>

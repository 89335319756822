<template>
  <article class="rolling-info" :class="platform" data-t="rolling-info">
    <StIcon name="circle-alert" class="info-icon" size="20" />
    <div class="content">
      <I18nT keypath="payments.withdrawal.feeMessage">
        <template #threshold>
          <b data-t="rolling-threshold">{{ formattedRolledThreshold }}</b>
        </template>
        <template #rollingAmount>
          <b data-t="rolling-amount">{{ formattedRollingAmount }}</b>
        </template>
      </I18nT>
      <StTooltip placement="top-start" class="tooltip-wrapper">
        <template #activator>
          <span class="about" data-t="rolling-tooltip-activator">
            &nbsp;
            {{ t('payments.withdrawal.rollingAbout') }}
          </span>
        </template>
        <div class="tooltip-content" data-t="rolling-tooltip">
          <I18nT keypath="payments.withdrawal.tooltip1" tag="p">
            <template #categories>
              <b data-t="rolling-categories">{{ rollingCategories }}</b>
            </template>
            <template #rate>
              <b data-t="rolling-min-bet-rate">{{ rollingMinBetRate }}</b>
            </template>
          </I18nT>
          <p>{{ t('payments.withdrawal.tooltip2') }}</p>
        </div>
      </StTooltip>
    </div>
  </article>
</template>

<script setup lang="ts">
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import Decimal from '@st/decimal'
import { truncRate } from '@st/utils'

const props = withDefaults(
  defineProps<{
    currencyCode: string
    rollingAmount: string
    rolledWithdrawalAmount: string
    platform?: 'mobile' | 'desktop'
  }>(),
  {
    platform: 'desktop',
  },
)

const { settings } = storeToRefs(useSettingsStore())

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => props.currencyCode),
})

const depositRollingCasinoCategories =
  settings.value?.depositRolling.casinoCategoryIds
const rollingMinBetRate = computed(() =>
  truncRate(
    new Decimal(
      settings.value?.depositRolling?.bettingMinBetRate || '1.3',
    ).toNumber(),
  ),
)

const { locale, t } = useI18n()

const { data: casinoCategories } = useStFetch('/casino/category/find', {
  method: 'post',
  body: computed(() => ({
    params: {
      language: locale.value as any,
    },
    pagination: {
      page: 0,
      perPage: 50,
      orderBy: [
        {
          fieldName: 'weight' as const,
          sortOrder: 'DESC' as const,
        },
      ],
    },
  })),
  transform: (response) =>
    response.data.map((category) => ({
      ...category,
      name: category.name || category.code,
    })),
  default: () => [],
})

const rollingCategories = computed(() => {
  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  return listFormatter.format(
    casinoCategories.value
      .filter(
        (category) => depositRollingCasinoCategories?.includes(category.id),
      )
      .map((category) => category.name),
  )
})

const formattedRollingAmount = computed(() => {
  if (!props.rollingAmount) return ''

  const absValue = new Decimal(props.rollingAmount).abs()

  return `${formatCrypto(absValue.toString())}`
})

const formattedRolledThreshold = computed(() =>
  formatCrypto(props.rolledWithdrawalAmount || '0'),
)
</script>

<style scoped>
.rolling-info {
  display: flex;
  gap: var(--spacing-150);

  padding: var(--spacing-150) var(--spacing-200);

  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);

  background: rgb(254 120 42 / 12%);
  border-radius: var(--border-radius-150);

  b {
    font: var(--desktop-text-sm-medium);
    color: var(--text-primary);
  }
}

.info-icon {
  flex-shrink: 0;
  color: var(--icon-brand);
}

.tooltip-wrapper {
  display: inline-flex;
}

.about {
  color: var(--text-link);
}

.tooltip-content {
  max-width: 240px;
  padding: var(--spacing-150) var(--spacing-200);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  b {
    font: var(--desktop-text-sm-medium);
    color: var(--text-primary);
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: var(--spacing-100);
    }
  }
}

.mobile {
  font: var(--mobile-text-content-regular);

  b {
    font: var(--mobile-text-content-regular);
  }

  .tooltip-content {
    font: var(--mobile-text-content-regular);

    b {
      font: var(--mobile-text-content-regular);
    }
  }
}
</style>

export function useValidateAddress() {
  const { t } = useI18n()

  const networkId = ref()
  const address = ref()

  const {
    execute: validateAddress,
    error: validateAddressError,
    refresh: validateRefresh,
  } = useStFetch('/payment-address/validate', {
    method: 'POST',
    immediate: false,
    watch: false,
    body: computed(() => ({
      networkId: networkId.value,
      address: address.value,
    })),
  })

  const errorCodes: Record<string, string> = {
    VALIDATION_ERROR: t('payments.addressBook.errorMessages.validationError'),
    USER_UNAUTHORIZED: t('payments.addressBook.errorMessages.userUnathorized'),
    PAYMENT_ADDRESS_ALREADY_EXISTS: t(
      'payments.addressBook.errorMessages.addressAlreadyExists',
    ),
    CURRENCY_NETWORK_PAIR_NOT_FOUND: t(
      'payments.addressBook.errorMessages.pairNotFound',
    ),
    CONFIRMATION_CODE_RESEND_TIMEOUT: t(
      'payments.addressBook.errorMessages.confirmationResetTimeout',
    ),
    PAYMENT_ADDRESS_VALIDATION_FOR_THIS_NETWORK_NOT_SUPPORTED: t(
      'payments.addressBook.errorMessages.paymentAddressValidationNotSupported',
    ),
    PAYMENT_ADDRESS_NOT_VALID: t(
      'payments.addressBook.errorMessages.paymentAddressNotValid',
    ),
    FORBIDDEN_COUNTRY: t('payments.addressBook.errorMessages.forbiddenCountry'),
  }

  const validationErrorMessage = computed(() => {
    const validationErrorBody = validateAddressError.value?.data

    if (validationErrorBody) {
      const { error: errorCode } = validationErrorBody

      return errorCodes[errorCode]
    }

    return undefined
  })

  return {
    networkId,
    address,
    validateAddress,
    validateRefresh,
    validationErrorMessage,
  }
}

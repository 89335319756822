<template>
  <div class="tooltip">
    <div class="content">
      <ul>
        <li>
          <div class="bullet">1</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-1') }}
          </div>
        </li>
        <li>
          <div class="bullet">2</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-2') }}
          </div>
        </li>
        <li>
          <div class="bullet">3</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-3') }}
          </div>
        </li>
        <li>
          <div class="bullet">4</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-4') }}
          </div>
        </li>
        <li>
          <div class="bullet">5</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-5') }}
          </div>
        </li>
        <li>
          <div class="bullet">6</div>
          <div class="text">
            {{ t('payments.deposit.tooltip-bullet-6') }}
          </div>
        </li>
      </ul>
      <div class="about">
        <StButton
          type="text-primary"
          :label="t('payments.deposit.aboutDeposit')"
          icon="book-open-solid"
          :to="`${supportUrl}/collections/8926424`"
          target="_blank"
          is-left-icon
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { t } = useI18n()
const { supportUrl } = useSupportUrl()
</script>

<style scoped>
.tooltip {
  width: 328px;
  padding: var(--spacing-200);
  background-color: rgba(0 0 0 / 76%);
  border-radius: var(--border-radius-150);

  ul {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-150);

    margin: 0;
    padding: 0;

    list-style: none;

    li {
      display: flex;
      gap: var(--spacing-100);

      .bullet {
        flex-shrink: 0;

        width: var(--spacing-250);
        height: var(--spacing-250);

        font: var(--desktop-text-xs-medium);
        line-height: var(--spacing-250);
        color: var(--palette-light-1000);
        text-align: center;
        vertical-align: middle;

        background-color: var(--background-tertiary);
        border-radius: var(--border-radius-full);
      }

      .text {
        font: var(--desktop-text-sm-medium);
        color: var(--text-secondary);
      }
    }
  }

  .about {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: var(--spacing-400);

    color: var(--button-primary-default);

    .text {
      font: var(--desktop-text-sm-semibold);
    }
  }
}
</style>

<template>
  <div class="second-step" :class="platform">
    <div class="back-button-wrapper">
      <StButton
        :label="t('payments.withdrawal.goBack')"
        size="l"
        type="text-only"
        icon="chevron-left"
        is-left-icon
        @click="emit('goBack')"
      />
    </div>
    <div id="mercuryo-widget" class="widget"></div>
  </div>
</template>

<script setup lang="ts">
import { useMercuryo } from '../../../composables/useMercuryo'

const emit = defineEmits<{
  (e: 'goBack'): void
}>()

const props = withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
    signKey: string
    address: string
    currencyCode: string
  }>(),
  { platform: 'desktop' },
)

const { initMercuryo } = useMercuryo()
const { t } = useI18n()

onMounted(() => {
  initMercuryo({
    address: props.address,
    signature: props.signKey,
    widgetId: 'mercuryo-widget',
    currency: props.currencyCode,
  })
})
</script>

<style scoped>
.second-step {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  margin-top: var(--spacing-300);
}

.widget {
  height: 700px;
}

.second-step.mobile {
  margin-top: 0;
  padding: var(--spacing-200);

  .widget {
    height: 550px;
  }
}
</style>

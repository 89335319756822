import { useWithdrawalStore } from '../../../stores/useWithdrawalStore'

const validationErrors = [
  'CONFIRMATION_CODE_NOT_VALID',
  'CONFIRMATION_CODE_MAX_ATTEMPTS_EXCEEDED',
  'VALIDATION_ERROR',
]

export function useWithdrawal() {
  function checkValidationError(err: string) {
    return validationErrors.includes(err)
  }

  const withdrawalStore = useWithdrawalStore()
  const { withdrawalData } = storeToRefs(withdrawalStore)

  const {
    execute: sendCodeExecute,
    refresh: sendCodeRefresh,
    status: sendCodeStatus,
    error: sendCodeError,
  } = useStFetch('/withdrawal/create/code/send', {
    method: 'post',
    immediate: false,
    watch: false,
    body: computed(() => ({
      amount: withdrawalData.value?.amount as string,
      paymentAddressId: withdrawalData.value?.paymentAddressId as any,
      paymentAddress: withdrawalData.value?.address as string,
      currencyId: withdrawalData.value?.selectedCurrencyId as number,
      networkId: withdrawalData.value?.selectedNetworkId as number,
    })),
  })

  const confirmationCode = ref()

  const {
    execute: withdrawalExecute,
    error: createWithdrawalError,
    status: createWithdrawalStatus,
  } = useStFetch('/withdrawal/create', {
    method: 'post',
    immediate: false,
    watch: false,
    body: computed(() => ({
      amount: withdrawalData.value?.amount as string,
      paymentAddressId: withdrawalData.value?.paymentAddressId as any,
      code: confirmationCode.value,
      paymentAddress: withdrawalData.value?.address ?? '',
      currencyId: withdrawalData.value?.selectedCurrencyId as number,
      networkId: withdrawalData.value?.selectedNetworkId as number,
    })),
  })

  return {
    checkValidationError,
    sendCodeExecute,
    sendCodeRefresh,
    sendCodeStatus,
    sendCodeError,
    withdrawalExecute,
    createWithdrawalError,
    confirmationCode,
    createWithdrawalStatus,
  }
}

<template>
  <StSelectField is-default-click-disabled>
    <div class="address-input" :class="platform">
      <div class="top-labels">
        <div class="label">
          <span>{{ t('payments.withdrawal.walletAddress') }}</span>
        </div>
      </div>
      <div class="input-section">
        <div v-if="isDisabledInputField" class="address-wrapper">
          <div class="address-value" @click="toggleSelect">
            {{ inputValue }}
            <div v-if="isVerifiedAddress" class="verified-address-label">
              {{ t('payments.addressBook.confirmed') }}
            </div>
          </div>
          <div class="postfix-section">
            <StIcon
              class="postfix-icon"
              name="cross-large"
              size="16"
              @click="emit('clearSelectInput')"
            />
            <StIcon
              class="postfix-icon"
              name="book-solid"
              size="16"
              data-t="icon-postfix"
              @click="toggleSelect"
            />
          </div>
        </div>
        <template v-else>
          <component
            :is="platform === 'desktop' ? StInput : StTextarea"
            v-model="inputValue"
            class="input-element"
            :placeholder="t('payments.withdrawal.chooseWalletAddress')"
            :error="error"
            data-t="st-select-input"
          >
            <template #postfix>
              <div class="postfix-section">
                <StIcon
                  v-if="inputValue"
                  size="16"
                  class="postfix-icon"
                  name="cross-large"
                  @click="emit('clearSelectInput')"
                />
                <StButton
                  v-else
                  type="text-tertiary"
                  class="postfix-icon"
                  :label="t('payments.withdrawal.paste')"
                  @click="pasteAddress"
                />
                <StIcon
                  class="postfix-icon"
                  name="book-solid"
                  size="16"
                  data-t="icon-postfix"
                  @click="toggleSelect"
                />
              </div>
            </template>
          </component>
        </template>
      </div>
    </div>
  </StSelectField>
</template>

<script setup lang="ts">
import type { BaseSelectApi } from '@st/ui/components/StBaseSelect/types'
import StSelectField from '@st/ui/components/StBaseSelect/parts/StSelectField.vue'
import StInput from '@st/ui/components/StInput/StInput.vue'
import StTextarea from '@st/ui/components/StTextarea/StTextarea.vue'

type InputSize = 'm' | 's'

const emit = defineEmits<{
  (e: 'toggleSelect'): void
  (e: 'clearSelectInput'): void
  (e: 'onInputValueChanged', value: string): void
}>()

const props = withDefaults(
  defineProps<{
    error?: boolean
    size: InputSize
    platform?: 'desktop' | 'mobile'
    inputValue: string
    isDisabledInputField?: boolean
    isVerifiedAddress?: boolean
  }>(),
  {
    inputmode: 'text',
    size: 'm',
    platform: 'desktop',
    isDisabledInputField: false,
    isVerifiedAddress: false,
  },
)

const { t } = useI18n()

const inputValue = computed({
  get: () => props.inputValue,
  set: (value: string) => emit('onInputValueChanged', value),
})

const selectApi: BaseSelectApi | undefined = inject('StBaseSelectApi')
function toggleSelect() {
  selectApi?.toggleDropdown()
}

async function pasteAddress() {
  const textFromClipboard = await navigator.clipboard.readText()

  inputValue.value = textFromClipboard
}
</script>

<style scoped>
.address-input {
  width: 100%;
}

.input-section {
  position: relative;
}

.label {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: var(--spacing-075);

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);
}

.address-wrapper {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;
  justify-content: space-between;

  width: 100%;
  padding: var(--spacing-150);

  font: var(--desktop-text-md-medium);
  color: var(--text-primary);

  background-color: var(--background-secondary);
  border-radius: var(--border-radius-100);

  .second-value {
    color: var(--text-tertiary);
  }

  .placeholder {
    font: var(--desktop-text-md-medium);
    color: var(--text-quaternary);
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 0 1px var(--button-primary-default) inset;
  }

  &:focus {
    box-shadow:
      0 0 0 1px var(--button-primary-default) inset,
      var(--focus-rings-button-primary);
  }

  &.error {
    box-shadow: 0 0 0 1px var(--system-error) inset;
  }

  &:focus.error {
    box-shadow:
      0 0 0 1px var(--system-error) inset,
      var(--focus-rings-button-destructive);
  }
}

.address-value {
  align-items: center;
  word-break: break-all;
}

.postfix-icon {
  cursor: pointer;
  color: var(--icon-tertiary);
}

.verified-address-label {
  display: inline;

  max-width: fit-content;
  margin-left: var(--spacing-050);
  padding: 1px var(--spacing-050, 4px) 2px var(--spacing-050, 4px); /* stylelint-disable-line */

  font: var(--desktop-text-xxs-medium);
  color: var(--text-success);

  background: rgb(83 198 107 / 12%);
  border-radius: var(--border-radius-full);
}

.postfix-section {
  cursor: pointer;

  right: var(--spacing-150);

  display: flex;
  gap: var(--spacing-150);
  align-items: center;

  color: var(--text-quaternary);
}

.mobile {
  .address-wrapper {
    font: var(--desktop-text-sm-medium);
  }

  .input-element {
    :deep(.input) {
      padding-right: var(--spacing-500);
    }
  }
}
</style>

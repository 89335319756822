<template>
  <div class="buy-crypto" :class="platform" data-t="buy-crypto">
    <ClientOnly>
      <KeepAlive>
        <FirstStep
          v-if="current === 'firstStep'"
          :platform="platform"
          @go-to-next-step="goToSecondStep"
        />
      </KeepAlive>
      <SecondStep
        v-if="current === 'secondStep'"
        :platform="platform"
        :frame-url="frameUrl"
        @go-back="goTo('firstStep')"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'

withDefaults(
  defineProps<{
    platform?: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { current, goTo } = useStepper(['firstStep', 'secondStep'])

const frameUrl = ref('')

function goToSecondStep(data: { frameUrl: string }) {
  frameUrl.value = data.frameUrl
  goTo('secondStep')
}
</script>

<style scoped>
.buy-crypto {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.mobile.buy-crypto {
  margin-top: 0;
  padding-top: 0;
}
</style>

<template>
  <div class="buy-crypto" :class="platform" data-t="buy-crypto">
    <FirstStep
      v-if="current === 'firstStep'"
      :platform="platform"
      @go-to-next-step="goToSecondStep"
    />
    <SecondStep
      v-if="current === 'secondStep'"
      :platform="platform"
      :sign-key="signKey"
      :address="address"
      :currency-code="currencyCode"
      @go-back="goTo('firstStep')"
    />
  </div>
</template>

<script setup lang="ts">
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'

withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { current, goTo } = useStepper(['firstStep', 'secondStep'])

const signKey = ref('')
const address = ref('')
const currencyCode = ref('')

function goToSecondStep(data: {
  address: string
  key: string
  currencyCode: string
}) {
  signKey.value = data.key
  address.value = data.address
  currencyCode.value = data.currencyCode

  goTo('secondStep')
}
</script>

<style scoped>
.buy-crypto {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  min-height: 600px;
}

.mobile.buy-crypto {
  margin-top: 0;
  padding-top: 0;
}
</style>

<template>
  <form class="first-step" :class="platform" @submit.prevent="handleSubmit">
    <NavigationTabs :type="platform" />
    <div class="content">
      <div class="content-top">
        <h2 v-if="platform === 'desktop'">
          {{ t('payments.buyCrypto.title') }}
        </h2>
        <div class="top-info">
          <div class="info-block">
            <div class="image">
              <img src="../assets/balance.png" width="30" />
            </div>
            <div class="text">
              {{
                platform === 'desktop'
                  ? t('payments.buyCrypto.topInfo1')
                  : t('payments.buyCrypto.mTopInfo1')
              }}
            </div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/fee.png" width="30" />
            </div>
            <div class="text">{{ t('payments.buyCrypto.topInfo2') }}</div>
          </div>
          <div class="info-block">
            <div class="image">
              <img src="../assets/popular.png" width="30" />
            </div>
            <div class="text">
              {{ t('payments.buyCrypto.topInfo3') }}
            </div>
          </div>
        </div>
      </div>
      <div class="selects-wrapper">
        <DepositBonusBanner
          v-if="favoriteNewDepositBonus"
          :bonus="favoriteNewDepositBonus"
          :platform="platform"
          :selected-currency-code="selectedCurrency?.code"
          :selected-currency-icon="selectedCurrency?.icon"
          type="buy"
        />
        <div class="amount-wrapper">
          <InputWithSelect
            v-model:select="fiatCurrencyId"
            v-model:input="fiatAmount"
            :error="fiatAmountError"
            :input-placeholder="t('buyCrypto.enterAmount')"
            :platform="platform"
            :select-items="mappedGeoList"
            :title="t('payments.buyCrypto.inputTitle1')"
            @handle-focus="handleFiatAmountFocus"
            @update:input="onDecimalInputUpdate('fiat')"
          />
          <InputWithSelect
            v-model:select="currencyId"
            v-model:input="amount"
            :error="amountError"
            :input-placeholder="t('buyCrypto.enterAmount')"
            :platform="platform"
            :select-items="cryptoCurrenciesList"
            :title="t('payments.buyCrypto.inputTitle2')"
            :subtitle="convertRateText"
            class="amount-input-select"
            @update:input="onDecimalInputUpdate('crypto')"
            @handle-focus="handleAmountFocus"
          />
          <div class="amount-buttons">
            <StButton
              v-for="amount in fastAmounts"
              :key="amount"
              :label="formatCrypto(amount)"
              type="gray"
              size="s"
              @click="handleAmountClick(amount)"
            />
          </div>
        </div>
      </div>
      <PaymentMethods
        :is-disabled="!fiatAmount"
        :platform="platform"
        :methods="methods"
        :selected-method="selectedMethod?.fixedRateId"
        :amount="fiatAmount"
        :selected-currency="currencyId"
        @payment-method-update="handleMethodUpdate"
      />
      <div class="disclaimer">
        {{ t('payments.buyCrypto.disclaimer') }}
      </div>

      <div class="button-wrapper">
        <StButton
          :size="platform === 'desktop' ? 'xl' : 'l'"
          :label="
            amount
              ? t('payments.buyCrypto.buy2', { amount: recieveAmount })
              : t('payments.buyCrypto.buy')
          "
          class="buy-button"
          :loading="isLoading"
          :disabled="isDisabledButton"
          submit
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusBanner from '@st/bonuses/components/DepositBonusBanner/DepositBonusBanner.vue'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDepositBonusesStore } from '@st/bonuses/stores/useDepositBonusesStore'
import { isValidNumber, minBy } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import { useCurrenciesStore } from '../../../stores/useCurrenciesStore'
import { useCalypso } from '../../../composables/useCalypso'
import InputWithSelect from '../../InputWithSelect/InputWithSelect.vue'
import { useAccountsStore } from '../../../stores/useAccountsStore'
import PaymentMethods from './PaymentMethods.vue'

const emit = defineEmits<{
  (e: 'goToNextStep', value: { frameUrl: string }): void
}>()

const props = withDefaults(
  defineProps<{
    platform: 'mobile' | 'desktop'
  }>(),
  { platform: 'desktop' },
)

const { t } = useI18n()
const stFetch = useRawStFetch()

const currenciesStore = useCurrenciesStore()
const accountsStore = useAccountsStore()
const { currencies, appCurrency } = storeToRefs(currenciesStore)
const { realAccounts } = storeToRefs(accountsStore)

const { allowedCurrencyIds, getLimitsByCurrencyId, getFastAmounts } =
  useCalypso()

const SELECTED_CURRENCY_ID = 1

const currencyId = ref(SELECTED_CURRENCY_ID)
const amount = ref('')

const currentInputFocus = ref<'crypto' | 'fiat'>()

function handleAmountFocus() {
  currentInputFocus.value = 'crypto'
}

function handleFiatAmountFocus() {
  currentInputFocus.value = 'fiat'
}

const networkId = computed(() => {
  const currency = currencies.value[currencyId.value]
  const filteredCurrency = currency.networks.filter(
    (network) => network.allowedForCalypso,
  )

  return filteredCurrency[0].id
})

const selectedCurrency = computed(() =>
  currenciesStore.getCurrencyById(currencyId.value),
)

const { format: formatCrypto } = useCryptoFormatter({
  currency: computed(() => selectedCurrency.value.code),
})

const { format: formatCurrency } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})

const cryptoCurrenciesList = computed(() =>
  realAccounts.value
    .filter((account) => allowedCurrencyIds.value.includes(account.currencyId))
    .map((account) => ({
      id: account.currencyId,
      title: account.code,
      value:
        props.platform === 'mobile' ? formatCrypto(account.balance) : undefined,
      subValue:
        props.platform === 'mobile'
          ? `~${formatCurrency(account.fiatBalance)}`
          : undefined,
      icon: account.icon,
    })),
)

const fiatAmount = ref('')

const { data: calypsoGeoData, execute: fetchDepositCalypsoGeo } = useStFetch(
  '/deposit/calypso/geo',
  {
    method: 'post',
    body: computed(() => ({
      currencyId: currencyId.value,
      networkId: networkId.value,
    })),
    immediate: false,
    watch: false,
  },
)

watchEffect(() => {
  if (!currencyId.value || !networkId.value) return
  fetchDepositCalypsoGeo()
})

const fiatCurrencyId = ref()
const geoList = computed(() => calypsoGeoData.value?.geo || [])
const calypsoMethodId = computed(() => calypsoGeoData.value?.methodId)

const iconsMap: Record<string, IconName> = {
  PEN: 'f-per',
  BRL: 'f-bra',
  COP: 'f-col',
  PHP: 'f-phl',
  ZAR: 'f-zaf',
  KRW: 'f-kor',
  CLP: 'f-chl',
  MXN: 'f-mex',
  HKD: 'f-hkg',
  GHS: 'f-gha',
  THB: 'f-tha',
  NGN: 'f-nga',
  AZN: 'f-aze',
  KES: 'f-ken',
  NZD: 'f-nzl',
  MYR: 'f-mys',
  USD_ECU: 'f-ecu',
  VES: 'f-ven',
  IDR: 'f-idn',
  SGD: 'f-sgp',
  EUR: 'f-eu',
}

const mappedGeoList = computed(() =>
  geoList.value.map((item) => ({
    id: item.geoId,
    title: String(item.geoCode),
    icon: iconsMap[item.geoCode] ?? '',
  })),
)

watch(fiatCurrencyId, () => {
  amount.value = ''
})

watch(geoList, (newValue) => {
  fiatCurrencyId.value = newValue[0]?.geoId
})

const { data: calypsoRateData } = useStFetch('/deposit/calypso/rate', {
  method: 'post',
  body: computed(() => ({
    currencyId: currencyId.value,
    geoId: fiatCurrencyId.value,
    methodId: calypsoMethodId.value as string,
  })),
  immediate: false,
})

const methods = computed(() => calypsoRateData.value?.method || [])
const selectedMethod = ref()

watch(methods, (newValue) => {
  if (!newValue) return

  const { 0: firstElement } = newValue
  selectedMethod.value = firstElement
})

function handleMethodUpdate(methodId: string) {
  if (!methods.value) return

  selectedMethod.value = methods.value?.find(
    (method) => method.fixedRateId === methodId,
  )
}

const convertRate = computed(() => selectedMethod.value?.fixedRate || 1)
const minimalConvertRate = computed(() => {
  const foundMethod = minBy(methods.value, (method) => method.fixedRate)

  return foundMethod?.fixedRate
})

const selectedFiatCurrency = computed(() =>
  mappedGeoList.value.find((currency) => currency.id === fiatCurrencyId.value),
)

const convertRateText = computed(() => {
  if (!convertRate.value || !minimalConvertRate.value) return ''

  const rate = new Decimal(1)
    .mul(minimalConvertRate.value)
    .toDecimalPlaces(2)
    .toString()

  return `${formatCrypto(1)} ≈ ${rate} ${
    selectedFiatCurrency.value?.title ?? ''
  }`
})

const limits = computed(() => getLimitsByCurrencyId(currencyId.value))

function onDecimalInputUpdate(type: 'crypto' | 'fiat') {
  if (type === 'crypto') {
    amount.value = amount.value.trim().replace(',', '.')
  }

  if (type === 'fiat') {
    fiatAmount.value = fiatAmount.value.trim().replace(',', '.')
  }
}

const isLoading = ref(false)
const amountError = ref('')
const fiatAmountError = ref('')

function calculateAmount() {
  if (!isValidNumber(fiatAmount.value)) {
    fiatAmountError.value = t('payments.withdrawal.validationError')
    return
  }

  amount.value = new Decimal(fiatAmount.value)
    .div(convertRate.value)
    .toDecimalPlaces(8)
    .toString()

  onDecimalInputUpdate('crypto')
}

function calculateFiatAmount() {
  if (!isValidNumber(amount.value)) {
    amountError.value = t('payments.withdrawal.validationError')
    return
  }

  fiatAmount.value = new Decimal(amount.value)
    .mul(convertRate.value)
    .toDecimalPlaces(8)
    .toString()
}

watch(amount, (newValue) => {
  amountError.value = ''
  fiatAmountError.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    fiatAmount.value = ''

    return
  }

  if (currentInputFocus.value === 'crypto') {
    calculateFiatAmount()
  }
})

watch(fiatAmount, (newValue) => {
  amountError.value = ''
  fiatAmountError.value = ''

  if (!convertRate.value) return

  if (!newValue) {
    amount.value = ''

    return
  }

  if (currentInputFocus.value === 'fiat') {
    calculateAmount()
  }
})

const recieveAmount = computed(() => {
  if (!isValidNumber(amount.value)) return ''

  return formatCrypto(amount.value)
})

const isDisabledButton = computed(() => {
  if (!isValidNumber(amount.value)) return true

  if (fiatAmountError.value || amountError.value) return true

  return !amount.value
})

const fastAmounts = computed(() =>
  getFastAmounts(currencyId.value, networkId.value),
)

function handleAmountClick(amountValue: string) {
  amount.value = amountValue

  calculateFiatAmount()
}

function validate(): boolean {
  if (
    !amount.value ||
    new Decimal(amount.value).greaterThan(limits.value.max)
  ) {
    amountError.value = t('payments.buyCrypto.maxAmountError', {
      maxAmount: formatCrypto(limits.value.max),
    })
    return false
  }

  if (new Decimal(amount.value).lessThan(limits.value.min)) {
    amountError.value = t('payments.buyCrypto.minAmountError', {
      minAmount: formatCrypto(limits.value.min),
    })
    return false
  }

  return true
}

const toast = useToast()

async function handleSubmit() {
  const isValid = validate()
  if (!isValid) return

  isLoading.value = true

  try {
    const response = await stFetch('/deposit/calypso/init', {
      method: 'post',
      body: {
        amount: amount.value,
        currencyId: currencyId.value,
        networkId: networkId.value,
        rateId: selectedMethod.value.fixedRateId,
      },
    })
    emit('goToNextStep', { frameUrl: response.calypsoDepositLink })
  } catch (e) {
    toast.open({
      label: t('payments.buyCrypto.somethingWentWrong'),
      type: 'negative',
    })
  } finally {
    isLoading.value = false
  }
}

const { favoriteNewDepositBonus } = storeToRefs(useDepositBonusesStore())
</script>

<style scoped>
.first-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;

  height: 100%;
  height: 730px;
  padding-top: var(--spacing-300);
}

.content {
  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: var(--spacing-200);
  justify-content: flex-start;

  height: 100%;
}

.selects-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  margin: var(--spacing-050) 0 var(--spacing-100);
}

.amount-wrapper {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
}

.amount-input-select {
  margin-top: var(--spacing-100);
}

.currency {
  color: var(--text-tertiary);
}

h2 {
  margin: var(--spacing-100) 0 0;
  font: var(--desktop-text-2xl-semibold);
}

.top-info {
  display: flex;
  gap: var(--spacing-300);
  margin-top: var(--spacing-200);
}

.info-block {
  display: flex;
  gap: var(--spacing-100);
  align-items: center;

  .text {
    font: var(--desktop-text-sm-medium);
    color: var(--text-secondary);
  }

  .image {
    height: 28px;
  }
}

.disclaimer {
  display: flex;
  flex-grow: 1;
  align-items: flex-end;

  margin-top: var(--spacing-100);
  margin-bottom: var(--spacing-050);

  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;

  p {
    margin: 0;
    padding: 0;
  }

  span {
    color: var(--text-primary);
  }
}

.disclaimer-title {
  color: var(--text-primary);
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.buy-button {
  width: 100%;
}

.amount-buttons {
  display: flex;
  gap: var(--spacing-050);
}

.first-step.mobile {
  height: 100%;
  min-height: auto;
  padding-top: 0;

  h2 {
    margin: 0;
    font: var(--mobile-title-1-semibold);
  }

  .content {
    overflow: hidden;
    overflow-y: auto;
    gap: var(--spacing-150);
    padding: var(--spacing-200) var(--spacing-200) 0;
  }

  .button-wrapper {
    padding: var(--spacing-050) var(--spacing-200) var(--spacing-200);
  }

  .amount-buttons {
    margin-top: calc(-1 * var(--spacing-100));
  }

  .disclaimer {
    margin-bottom: 0;
    padding-bottom: 0;
    font: var(--mobile-caption-1-regular);
  }

  .top-info {
    gap: var(--spacing-125);
    margin-top: 0;
    padding-bottom: 0;
    border-bottom: none;
  }

  .selects-wrapper {
    margin-top: 0;
  }

  .info-block {
    .text {
      font: var(--mobile-caption-1-regular);
    }
  }
}
</style>

<template>
  <BuyCryptoCalypso v-if="isEnabledCalypso" />
  <BuyCryptoMercuryo v-else-if="isEnabledMercuryo" />
  <div v-else />
</template>

<script setup lang="ts">
import BuyCryptoCalypso from '../BuyCryptoCalypso/BuyCryptoCalypso.vue'
import BuyCryptoMercuryo from '../BuyCryptoMercuryo/BuyCryptoMercuryo.vue'

const { isEnabled: isEnabledCalypso } = useCalypso()
const { isEnabledMercuryo } = useMercuryo()
</script>

<template>
  <div class="deposit-methods" :class="type" data-t="deposit-methods">
    <div class="header">
      <h2>{{ t('payments.deposit.depositVariants') }}</h2>
      <div class="benefits">
        <div class="benefit">
          <div class="image">
            <img src="./assets/Perc.png" width="24" />
          </div>
          <div class="title">{{ t('payments.deposit.noCommission') }}</div>
        </div>
        <div class="benefit">
          <div class="image">
            <img src="./assets/Bolt.png" width="24" />
          </div>
          <div class="title">{{ t('payments.deposit.fastDeposit') }}</div>
        </div>
        <div class="benefit">
          <div class="image">
            <img src="./assets/Wallet.png" width="24" />
          </div>
          <div class="title">
            {{ t('payments.deposit.permanentCryptoWallets') }}
          </div>
        </div>
      </div>
    </div>
    <div class="curencies">
      <template v-for="currency in filteredCurrencies" :key="currency.id">
        <div
          v-if="currency.type !== 'fiat'"
          class="currency-card"
          :class="{ disabled: !currency.allowedForDeposit }"
          data-t="currency-card"
          @click="handleCurrencyClick(currency)"
        >
          <div class="currency-title">
            <StIcon :name="currency.icon" :size="currencyIconSize" />
            <div v-if="!currency.allowedForDeposit" class="lock-icon">
              <StIcon name="lock-solid" size="12" />
            </div>
            <div class="code-wrapper">
              <div class="code">{{ currency.code }}</div>
              <div class="name">{{ currency.name }}</div>
            </div>
          </div>
          <div class="bottom-section">
            <div class="deposit">
              <div class="title">{{ t('payments.deposit.deposit') }}</div>
              <div class="value">
                {{ t('payments.deposit.minimal') }}
                <span data-t="minimal-deposit">
                  {{ minimalDeposit(currency.id) }}
                </span>
              </div>
            </div>
            <div class="networks">
              <div class="title">{{ t('payments.deposit.networks') }}</div>
              <div class="network-list" data-t="network-list">
                <StTooltip placement="top">
                  <div class="tooltip">
                    <div class="list">
                      <div
                        v-for="(network, tooltipIdx) in currency.networks"
                        :key="tooltipIdx"
                        class="element"
                      >
                        <div class="icon">
                          <StIcon :name="network.icon" :size="16" />
                        </div>
                        <div class="code">{{ network.description }}</div>
                        <div class="name">{{ network.name }}</div>
                      </div>
                    </div>
                  </div>
                  <template #activator>
                    <div
                      v-for="(network, networkIdx) in currency.networks"
                      :key="networkIdx"
                      data-t="network-icon"
                      class="network-icon-wrapper"
                      :style="{ 'z-index': 999 - networkIdx }"
                    >
                      <StIcon :name="network.icon" :size="networkIconSize" />
                    </div>
                  </template>
                </StTooltip>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useCurrenciesStore } from '../../stores/useCurrenciesStore'
import { useTariffsStore } from '../../stores/useTariffsStore'
import { useConverterStore } from '../../stores/useConverterStore'
import type { Currency } from '../../interfaces'

const emit = defineEmits<{
  (e: 'handleCurrencySelected', id: number): void
}>()

const props = withDefaults(
  defineProps<{
    type: 'desktop' | 'mobile'
  }>(),
  { type: 'desktop' },
)

const { t } = useI18n()
const { currencies, appCurrency } = storeToRefs(useCurrenciesStore())

const { format } = useCurrencyFormatter({ currency: appCurrency.value.code })

const { findMinimalLimit } = useTariffsStore()

function handleCurrencyClick(currency: Currency) {
  if (currency.allowedForDeposit) {
    emit('handleCurrencySelected', currency.id)
  }
}

const { convert } = useConverterStore()

const minimalDeposit = computed(() => (currencyId: number) => {
  const foundLimit = findMinimalLimit({ currencyId, operationType: 'deposit' })
  if (!foundLimit) return ''

  const currency = currencies.value[currencyId]

  return format(
    convert(foundLimit.minAmount, {
      from: currency.code,
      to: appCurrency.value.code,
    }),
  )
})

const filteredCurrencies = computed(() =>
  Object.values(currencies.value).filter(
    (currency) => currency.allowedForAccount,
  ),
)

const currencyIconSize = computed(() => (props.type === 'desktop' ? 36 : 32))
const networkIconSize = computed(() => (props.type === 'desktop' ? 20 : 16))
</script>

<style scoped>
.deposit-methods {
  display: flex;
  flex-direction: column;
}

.header {
  margin-bottom: var(--spacing-250);

  h2 {
    margin: var(--spacing-100) 0 var(--spacing-200) 0;
    font: var(--desktop-text-2xl-semibold);
  }
}

.benefits {
  display: flex;
  gap: var(--spacing-300);
  font: var(--desktop-text-sm-medium);
  color: var(--palette-light-600);

  .benefit {
    display: flex;
    gap: var(--spacing-100);
    align-items: center;
  }

  .image {
    line-height: 0;
    filter: drop-shadow(0 0 2px rgb(187 151 93));
  }
}

.curencies {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-125);
  justify-content: space-between;

  max-width: 548px;
  min-height: 416px;
}

.network-icon-wrapper {
  overflow: hidden;
  display: flex;

  margin-left: calc(-1 * var(--spacing-100));
  padding: 0;

  border: 2px solid var(--background-primary);
  border-radius: var(--border-radius-full);
}

.currency-card {
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 176px;
  max-height: 132px;
  padding: var(--spacing-200) var(--spacing-200) var(--spacing-150)
    var(--spacing-200);

  background-color: var(--background-primary);
  border-radius: var(--border-radius-150);

  &:hover {
    background-color: var(--background-secondary);

    .network-icon-wrapper {
      background-color: var(--background-secondary);
      border: 2px solid var(--background-secondary);
    }
  }

  &.disabled {
    opacity: var(--disable-layer-opasity, 0.4);
  }
}

.currency-title {
  position: relative;
  display: flex;
  gap: var(--spacing-150);

  .code-wrapper {
    display: flex;
    flex-direction: column;

    .code {
      font: var(--desktop-text-md-medium);
    }

    .name {
      font: var(--desktop-text-xs-medium);
      color: var(--palette-light-600);
    }
  }

  .lock-icon {
    position: absolute;
    top: 24px;
    left: 24px;

    width: 18px;
    height: 18px;

    line-height: 0;
    text-align: center;

    background: var(--background-tertiary);
    border: var(--border-width-boldest) solid var(--background-primary);
    border-radius: var(--border-radius-full, 999px);
  }
}

.bottom-section {
  display: flex;
  gap: var(--spacing-150);
  justify-content: flex-start;

  .deposit {
    flex-grow: 1;
    min-width: 66px;

    .title {
      font: var(--desktop-text-xs-medium);
      color: var(--palette-light-600);
    }

    .value {
      font: var(--desktop-text-xs-semibold);
      white-space: nowrap;

      span {
        margin-left: var(--spacing-025);
        font: var(--desktop-text-md-semibold);
      }
    }
  }

  .networks {
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    min-width: 56px;

    .title {
      font: var(--desktop-text-xs-medium);
      color: var(--palette-light-600);
    }
  }

  .network-list {
    display: flex;
    padding-left: var(--spacing-075);
  }
}

.tooltip {
  width: 240px;
  padding: var(--spacing-150);

  .list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-150);

    .element {
      display: flex;
      gap: var(--spacing-125);
      align-items: center;
      justify-content: flex-start;

      .icon {
        line-height: 0;
      }

      .code {
        font: var(--desktop-text-xs-medium);
      }

      .name {
        flex-grow: 1;
        font: var(--desktop-text-xs-medium);
        color: var(--palette-light-600);
        text-align: right;
      }
    }
  }
}

.deposit-methods.mobile {
  overflow: hidden;
  overflow-y: auto;
  padding: 0 var(--spacing-200);

  .header {
    margin: 0;

    h2 {
      margin: var(--spacing-200) 0 0;
      font: var(--mobile-title-1-semibold);
    }
  }

  .image {
    filter: unset;
  }

  .curencies {
    gap: var(--spacing-100);
    min-height: auto;
    padding-bottom: var(--spacing-200);
  }

  .currency-card {
    width: calc(50% - var(--spacing-050));
    padding: var(--spacing-150) var(--spacing-100) var(--spacing-150)
      var(--spacing-150);
  }

  .code-wrapper {
    gap: var(--spacing-025);
  }

  /* stylelint-disable */
  .code {
    font: var(--mobile-text-semibold);
  }

  .name {
    font: var(--mobile-caption-1-regular);
  }

  /* stylelint-enable */

  .value {
    font: var(--mobile-caption-1-medium);

    span {
      font: var(--mobile-text-semibold);
    }
  }

  .bottom-section {
    height: 32px;
    margin-top: var(--spacing-200);
  }

  .networks {
    .title {
      font: var(--mobile-caption-1-regular);
    }
  }

  .benefits {
    margin: 0;
    padding: var(--spacing-150) 0;
  }

  .benefit {
    img {
      filter: grayscale(100%);
    }

    .title {
      font: var(--mobile-caption-1-regular);
    }
  }
}
</style>
